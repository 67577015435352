import { serialize } from '../utils/format';

export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORT_DETAIL_SUCCESS = 'GET_REPORT_DETAIL_SUCCESS';
export const BANNED_REPORT_STORY_SUCCESS = 'BANNED_REPORT_STORY_SUCCESS';
export const CHECKED_REPORT_SUCCESS = 'CHECKED_REPORT_SUCCESS';

// const {
//  keyword,
//  state = [],
//  reason = [],
//  sort = {
//    createdAt: -1,
//  },
//  page = 1,
//  pageSize = 20,
// } = data;
export function getReports(data) {
  if (!data.page) data.page = 1;
  if (!data.pageSize) data.pageSize = 20;
  const query = serialize(data);
  const { state } = data;
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_REPORTS_REQUEST'
    });
    return fetch(`/api/admin/v1/report?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          const splitAuthorization = localStorage.getItem("accessToken").split(' ');
          const token = splitAuthorization[1];
          const data = jsonResult.data.map((o) => {
            return {
              ...o,
              audioUrl: `${o.audioUrl}?token=${token}`
            };
          })
          return dispatch({
            type: 'GET_REPORTS_SUCCESS',
            payload: {
              ...jsonResult,
              data,
              state,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_REPORTS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function getReportDetail({ uid }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_REPORT_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/story/${uid}/report`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_REPORT_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_REPORT_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function reportBannedStory({ uid, cb, message }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'BANNED_REPORT_STORY_REQUEST'
    });
    return fetch(`/api/admin/v1/story/${uid}/report/banned`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        message,
      }),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          cb();
          return dispatch({
            type: 'BANNED_REPORT_STORY_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'BANNED_REPORT_STORY_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function checkedReport({ uid, message }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'CHECKED_REPORT_REQUEST'
    });
    return fetch(`/api/admin/v1/story/${uid}/report/checked`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        message,
      }),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          dispatch(getReportDetail({ uid }));
          return dispatch({
            type: 'CHECKED_REPORT_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'CHECKED_REPORT_FAILURE',
        payload: error,
        error: true
      }));
  };
}