import { serialize } from '../utils/format';

export const RESET_MOOD_DETAIL = 'RESET_MOOD_DETAIL';
export const GET_MOODS_SUCCESS = 'GET_MOODS_SUCCESS';
export const GET_MOOD_DETAIL_SUCCESS = 'GET_MOOD_DETAIL_SUCCESS';
export const UPDATE_MOOD_SUCCESS = 'UPDATE_MOOD_SUCCESS';
export const DELETED_MOOD_SUCCESS = 'DELETED_MOOD_SUCCESS';
export const CREATE_MOOD_EMOTION_SUCCESS = 'CREATE_MOOD_EMOTION_SUCCESS';
export const UPDATE_MOOD_EMOTION_SUCCESS = 'UPDATE_MOOD_EMOTION_SUCCESS';
export const DELETE_MOOD_EMOTION_SUCCESS = 'DELETE_MOOD_EMOTION_SUCCESS';

export function resetMoodDetail() {
  return {
    type: 'RESET_MOOD_DETAIL',
  };
}

export function getMoods(data) {
  if (!data.page) data.page = 1;
  if (!data.pageSize) data.pageSize = 20;
  const query = serialize(data);
  const { filterType } = data;
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_MOODS_REQUEST'
    });
    return fetch(`/api/admin/v1/mood?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_MOODS_SUCCESS',
            payload: {
              ...jsonResult,
              filterType,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_MOODS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function getMoodDetail({ id }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_MOOD_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/mood/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_MOOD_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_MOOD_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}


export function updateMood({ data }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'UPDATE_MOOD_REQUEST'
    });
    return fetch(`/api/admin/v1/mood`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(data),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        dispatch(getMoodDetail({ id: jsonResult.data.id }));
        if (result.ok) {
          return dispatch({
            type: 'UPDATE_MOOD_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'UPDATE_MOOD_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function deletedMood({ id, cb }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'DELETED_MOOD_REQUEST'
    });
    return fetch(`/api/admin/v1/mood/${id}`, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          cb();
          return dispatch({
            type: 'DELETED_MOOD_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'DELETED_MOOD_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function createMoodEmotion({ id, data }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'CREATE_MOOD_EMOTION_REQUEST'
    });
    return fetch(`/api/admin/v1/mood/${id}/emotion`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(data),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          dispatch(getMoodDetail({ id }));
          return dispatch({
            type: 'CREATE_MOOD_EMOTION_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'CREATE_MOOD_EMOTION_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function updateMoodEmotion({ id, emotionId, data }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'UPDATE_MOOD_EMOTION_REQUEST'
    });
    return fetch(`/api/admin/v1/mood/${id}/emotion/${emotionId}`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(data),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          dispatch(getMoodDetail({ id }));
          return dispatch({
            type: 'UPDATE_MOOD_EMOTION_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'UPDATE_MOOD_EMOTION_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function deleteMoodEmotion({ id, emotionId }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'DELETE_MOOD_EMOTION_REQUEST'
    });
    return fetch(`/api/admin/v1/mood/${id}/emotion/${emotionId}`, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          dispatch(getMoodDetail({ id }));
          return dispatch({
            type: 'DELETE_MOOD_EMOTION_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'DELETE_MOOD_EMOTION_FAILURE',
        payload: error,
        error: true
      }));
  };
}
