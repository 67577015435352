import { updateGodNav, resetNav } from './navigation';

export const CLEAN_AUTH = 'CLEAN_AUTH';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_GET_INFO_SUCCESS = 'AUTH_GET_INFO_SUCCESS';

export function cleanAuth() {
  localStorage.removeItem('accessToken');
  return (dispatch) => {
    dispatch(resetNav());
    return dispatch({
      type: CLEAN_AUTH,
    });
  }
}

export function authLogin({ email, password }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'CLEAN_FAILURE'
    });
    dispatch({
      type: 'AUTH_LOGIN_REQUEST'
    });
    return fetch(`/api/admin/v1/auth/login`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          password,
        }),
      })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          localStorage.setItem("accessToken", `Bearer ${jsonResult.data.accessToken}`);
          return dispatch({
            type: 'AUTH_LOGIN_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'AUTH_LOGIN_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function authRegister({ username, password }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'AUTH_REGISTER_REQUEST'
    });
    return fetch(`/api/admin/v1/auth/signup`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username,
          password,
        }),
      })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          localStorage.setItem("accessToken", `Bearer ${jsonResult.data.accessToken}`);
          return dispatch({
            type: 'AUTH_REGISTER_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'AUTH_REGISTER_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function authResetPassword({ oldPassword, password }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'AUTH_RESET_PASSWORD_REQUEST'
    });
    return fetch(`/api/admin/v1/auth/password`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          oldPassword,
          password,
        }),
      })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          localStorage.setItem("accessToken", `Bearer ${jsonResult.data.accessToken}`);
          return dispatch({
            type: 'AUTH_RESET_PASSWORD_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'AUTH_RESET_PASSWORD_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function authGetInfo() {
  return async(dispatch, getState) => {
    dispatch({
      type: 'AUTH_GET_INFO_REQUEST'
    });
    return fetch(`/api/admin/v1/auth/me`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem("accessToken"),
        },
      })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          dispatch({
            type: 'AUTH_GET_INFO_SUCCESS',
            payload: jsonResult,
          });
          return dispatch(updateGodNav());
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'AUTH_GET_INFO_FAILURE',
        payload: error,
        error: true
      }));
  };
}