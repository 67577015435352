import {
  GET_ROLES_SUCCESS,
} from '../actions/role';


export const dataInitialState = {
  roles: []
};

export const ACTION_HANDLERS = {
  [GET_ROLES_SUCCESS]: (state = {}, action) => {
    return { ...state, roles: action.payload.data };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
