import {
  RESET_VERSION_CONFIG_DETAIL,
  GET_VERSION_CONFIG_DETAIL_SUCCESS,
} from '../actions/versionConfig';


export const dataInitialState = {
  versionConfigDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_VERSION_CONFIG_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, versionConfigDetail: action.payload.data };
  },
  [RESET_VERSION_CONFIG_DETAIL]: (state = {}, action) => {
    return { ...state, versionConfigDetail: { versionConfigHistory: {} } };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
