export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';

export function fileUpload({ type, file, key }) {
  const formData = new FormData();
  formData.append('file', file);
  return async(dispatch, getState) => {
    dispatch({
      type: 'UPLOAD_FILE_REQUEST'
    });
    return fetch(`/api/admin/v1/file/upload/${type}`, {
      method: 'post',
      headers: {
        // Accept: 'application/json',
        // 'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: formData,
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'UPLOAD_FILE_SUCCESS',
            payload: {
              ...jsonResult,
              key,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'UPLOAD_FILE_FAILURE',
        payload: error,
        error: true
      }));
  };
}
