export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';


export function getRoles() {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_ROLES_REQUEST'
    });
    return fetch(`/api/admin/v1/role` , {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        console.log('GET_USERS_REQUEST', result);
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_ROLES_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_ROLES_FAILURE',
        payload: error,
        error: true
      }));
  };
}