import {
  CLEAN_AUTH,
  AUTH_LOGIN_SUCCESS,
  AUTH_REGISTER_SUCCESS,
  AUTH_GET_INFO_SUCCESS,
} from '../actions/auth';


export const dataInitialState = {
  id: '',
  username: '',
  email: '',
  accessToken: '',
};

export const ACTION_HANDLERS = {
  [CLEAN_AUTH]: (state = {}, action) => {
    return {
      ...dataInitialState,
    };
  },
  [AUTH_LOGIN_SUCCESS]: (state = {}, action) => {
    return { ...state, ...action.payload.data };
  },
  [AUTH_REGISTER_SUCCESS]: (state = {}, action) => {
    return { ...state, ...action.payload.data };
  },
  [AUTH_GET_INFO_SUCCESS]: (state = {}, action) => {
    return { ...state, ...action.payload.data };
  },
  // [AUTH_RESET_PASSWORD_SUCCESS]: (state = {}, action) => {
  //   return { ...state, ...action.payload.data };
  // },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
