import {
  GET_REPORTS_SUCCESS,
  GET_REPORT_DETAIL_SUCCESS,
  BANNED_REPORT_STORY_SUCCESS,
  CHECKED_REPORT_SUCCESS
} from '../actions/report';


export const dataInitialState = {
  reports: [],
  reportsMeta: {
    total: 0,
    lastPage: 0,
    perPage: 20,
  },
  unprocessedReports: [],
  unprocessedReportsMeta: {
    total: 0,
    lastPage: 0,
    perPage: 20,
  },
  reportDetail: {
    reports: [],
  },
};

export const ACTION_HANDLERS = {
  [GET_REPORTS_SUCCESS]: (state = {}, action) => {
    return {
      ...state,
      ...action.payload.state.indexOf(0) > -1 ? {
        unprocessedReports: action.payload.data,
        unprocessedReportsMeta: action.payload.meta,
      } : {
        reports: action.payload.data,
        reportsMeta: action.payload.meta,
      },
    };
  },
  [GET_REPORT_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, reportDetail: action.payload.data };
  },
  [BANNED_REPORT_STORY_SUCCESS]: (state = {}, action) => {
    return { ...state };
  },
  [CHECKED_REPORT_SUCCESS]: (state = {}, action) => {
    return { ...state };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
