import _ from 'lodash';
import {
  GET_FEEDBACKS_SUCCESS,
  GET_FEEDBACK_DETAIL_SUCCESS,
} from '../actions/feedback';


export const dataInitialState = {
  feedbackDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_FEEDBACKS_SUCCESS]: (state = {}, action) => {
    const result = { ...state };
    const { data, meta } = action.payload;
    _.set(result, `state${action.payload.state}.data`, data);
    _.set(result, `state${action.payload.state}.meta`, meta);
    return result;
  },
  [GET_FEEDBACK_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, feedbackDetail: action.payload.data };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
