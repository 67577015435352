import { serialize } from '../utils/format';

export const RESET_POI_DETAIL = 'RESET_POI_DETAIL';
export const GET_POIS_SUCCESS = 'GET_POIS_SUCCESS';
export const GET_POI_DETAIL_SUCCESS = 'GET_POI_DETAIL_SUCCESS';
export const CREATE_POI_SUCCESS = 'CREATE_POI_SUCCESS';
export const DELETED_POI_SUCCESS = 'DELETED_POI_SUCCESS';

export function resetPOIDetail() {
  return {
    type: 'RESET_POI_DETAIL',
  };
}

// const {
//  startDate,
//  endDate,
//  state = 1,
//  sort = {
//    startDate: -1,
//  },
//  page = 1,
//  pageSize = 20,
// } = data;
export function getPOIs(data) {
  if (!data.page) data.page = 1;
  if (!data.pageSize) data.pageSize = 20;
  const query = serialize(data);
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_POIS_REQUEST'
    });
    return fetch(`/api/admin/v1/poi?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_POIS_SUCCESS',
            payload: {
              ...jsonResult,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_POIS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function getPOIDetail({ id }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_POI_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/poi/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_POI_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_POI_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function createPOI(data) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'CREATE_POI_REQUEST'
    });
    return fetch(`/api/admin/v1/poi`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(data),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'CREATE_POI_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'CREATE_POI_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function deletedPOI({ id, cb }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'DELETED_POI_REQUEST'
    });
    return fetch(`/api/admin/v1/poi/${id}`, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          cb();
          return dispatch({
            type: 'DELETED_POI_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'DELETED_POI_FAILURE',
        payload: error,
        error: true
      }));
  };
}