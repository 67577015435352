import {
  GET_STORIES_SUCCESS,
  GET_STORY_DETAIL_SUCCESS,
  BANNED_STORY_SUCCESS,
  RESTORE_STORY_SUCCESS
} from '../actions/story';


export const userDataInitialState = {
  stories: [],
  storiesMeta: {
    total: 0,
    lastPage: 0,
    perPage: 10,
  },
  storyDetail: {},
};

export const ACTION_HANDLERS = {
  [GET_STORIES_SUCCESS]: (state = {}, action) => {
    return {
      ...state,
      stories: action.payload.data,
      storiesMeta: action.payload.meta,
    };
  },
  [GET_STORY_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, storyDetail: action.payload.data };
  },
  [BANNED_STORY_SUCCESS]: (state = {}, action) => {
    return { ...state };
  },
  [RESTORE_STORY_SUCCESS]: (state = {}, action) => {
    return { ...state };
  },
};

export default function (state = userDataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
