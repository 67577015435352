import _ from 'lodash';
import {
  GET_POIS_SUCCESS,
  GET_POI_DETAIL_SUCCESS,
  CREATE_POI_SUCCESS,
  RESET_POI_DETAIL
} from '../actions/poi';

export const dataInitialState = {
  pois: {
    data: [],
    meta: {},
  },
  poiDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_POIS_SUCCESS]: (state = {}, action) => {
    const result = { ...state };
    const { data, meta } = action.payload;
    _.set(result, `pois.data`, data);
    _.set(result, `pois.meta`, meta);
    return result;
  },
  [GET_POI_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, poiDetail: action.payload.data };
  },
  [CREATE_POI_SUCCESS]: (state = {}, action) => {
    return {
      ...state,
      poiDetail: {
        ...state.poiDetail,
        ...action.payload.data,
      },
    };
  },
  [RESET_POI_DETAIL]: (state = {}, action) => {
    return { ...state, poiDetail: {} };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
