import _ from 'lodash';
import {
  RESET_ANNOUNCEMENT_DETAIL,
  GET_ANNOUNCEMENTS_SUCCESS,
  GET_ANNOUNCEMENT_DETAIL_SUCCESS,
  UPDATE_ANNOUNCEMENT_SUCCESS,
} from '../actions/announcement';


export const dataInitialState = {
  announcementDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_ANNOUNCEMENTS_SUCCESS]: (state = {}, action) => {
    const result = JSON.parse(JSON.stringify(state));
    const { filterType, data, meta } = action.payload;
    _.set(result, `${filterType}.data`, data);
    _.set(result, `${filterType}.meta`, meta);
    return result;
  },
  [GET_ANNOUNCEMENT_DETAIL_SUCCESS]: (state = {}, action) => {
    return {
      ...state,
      announcementDetail: {
        ...action.payload.data,
      }
    };
  },
  [UPDATE_ANNOUNCEMENT_SUCCESS]: (state = {}, action) => {
    return {
      ...state,
      announcementDetail: {
        ...state.announcementDetail,
        ...action.payload.data,
      },
    };
  },
  [RESET_ANNOUNCEMENT_DETAIL]: (state = {}, action) => {
    return { ...state, announcementDetail: {} };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
