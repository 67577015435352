import { getUsers } from './user';

export const CONFIRM_USER_SUCCESS = 'CONFIRM_USER_SUCCESS';
export const BANNED_USERS_SUCCESS = 'BANNED_USERS_SUCCESS';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';

export function confirmUser({ id, page, isConfirmed }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'CONFIRM_USER_REQUEST'
    });
    return fetch(`/api/admin/v1/user/${id}/confirm` , {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        console.log('CONFIRM_USER_REQUEST', result);
        const jsonResult = await result.json();
        if (result.ok) {
          dispatch(getUsers({ page, isConfirmed }));
          return dispatch({
            type: 'CONFIRM_USER_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'CONFIRM_USER_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function bannedUser({ id, cb }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'BANNED_USERS_REQUEST'
    });
    return fetch(`/api/admin/v1/user/${id}/banned`, {
        method: 'put',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem("accessToken"),
        },
      })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          cb();
          return dispatch({
            type: 'BANNED_USERS_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'BANNED_USERS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function updateUserRole({ id, roleCode }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'UPDATE_USER_ROLE_REQUEST'
    });
    return fetch(`/api/admin/v1/user/${id}/role`, {
        method: 'put',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          roleCode,
        }),
      })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'UPDATE_USER_ROLE_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'UPDATE_USER_ROLE_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function updateUserPassword({ id, password }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'UPDATE_USER_PASSWORD_REQUEST'
    });
    return fetch(`/api/admin/v1/user/${id}/password`, {
        method: 'put',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          password,
        }),
      })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'UPDATE_USER_PASSWORD_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'UPDATE_USER_PASSWORD_FAILURE',
        payload: error,
        error: true
      }));
  };
}