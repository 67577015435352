export const RESET_VERSION_CONFIG_DETAIL = 'RESET_VERSION_CONFIG_DETAIL';
export const GET_VERSION_CONFIG_DETAIL_SUCCESS = 'GET_VERSION_CONFIG_DETAIL_SUCCESS';
export const UPDATE_VERSION_CONFIG_SUCCESS = 'UPDATE_VERSION_CONFIG_SUCCESS';

export function resetVersionConfigDetail() {
  return {
    type: 'RESET_VERSION_CONFIG_DETAIL',
  };
}

export function getVersionConfigDetail() {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_VERSION_CONFIG_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/config/version`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_VERSION_CONFIG_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_VERSION_CONFIG_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function updateVersionConfig({ data }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'UPDATE_VERSION_CONFIG_REQUEST'
    });
    return fetch(`/api/admin/v1/config/version`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({ ...data }),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          dispatch(getVersionConfigDetail({
            key: jsonResult.data.key,
          }));
          return dispatch({
            type: 'UPDATE_VERSION_CONFIG_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'UPDATE_VERSION_CONFIG_FAILURE',
        payload: error,
        error: true
      }));
  };
}