import _ from 'lodash';
import {
  RESET_EVENT_DETAIL,
  GET_EVENTS_SUCCESS,
  GET_EVENT_DETAIL_SUCCESS,
  CREATE_DRAFT_EVENT_SUCCESS
} from '../actions/event';


export const dataInitialState = {
  eventDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_EVENTS_SUCCESS]: (state = {}, action) => {
    const result = JSON.parse(JSON.stringify(state));
    const { filterType, category, type, data, meta } = action.payload;
    _.set(result, `${filterType}.${category}.${type}.data`, data);
    _.set(result, `${filterType}.${category}.${type}.meta`, meta);
    return result;
  },
  [GET_EVENT_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, eventDetail: action.payload.data };
  },
  [CREATE_DRAFT_EVENT_SUCCESS]: (state = {}, action) => {
    return {
      ...state,
      eventDetail: {
        ...state.eventDetail,
        ...action.payload.data,
      },
    };
  },
  [RESET_EVENT_DETAIL]: (state = {}, action) => {
    return { ...state, eventDetail: {} };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
