import {
  RECEIVED_UPDATE_USER,
  CLEAN_UPDATE_USER,
  GET_USERS_SUCCESS,
  CREATE_USERS_SUCCESS,
  GET_USER_DETAIL_SUCCESS,
  PUT_USER_SUCCESS
} from '../actions/user';
import {
  CONFIRM_USER_SUCCESS,
  BANNED_USERS_SUCCESS,
  UPDATE_USER_PASSWORD_SUCCESS,
} from '../actions/userOperate';


export const userDataInitialState = {
  name: '',
  confirmedUsers: [],
  confirmedUsersMeta: {
    total: 0,
    lastPage: 0,
    perPage: 10,
  },
  unConfirmedUsers: [],
  unConfirmedUsersMeta: {
    total: 0,
    lastPage: 0,
    perPage: 10,
  },
  userDetail: {},
};

export const ACTION_HANDLERS = {
  [RECEIVED_UPDATE_USER]: (state = {}, action) => {
    return { ...state, ...action.data, frontUpdateTime: new Date() };
  },
  [CLEAN_UPDATE_USER]: (state = {}, action) => {
    return {
      ...userDataInitialState,
    };
  },
  [GET_USERS_SUCCESS]: (state = {}, action) => {
    return {
      ...state,
      ...action.payload.isConfirmed ? {
        confirmedUsers: action.payload.data,
        confirmedUsersMeta: action.payload.meta,
      } : {
        unConfirmedUsers: action.payload.data,
        unConfirmedUsersMeta: action.payload.meta,
      }
    };
  },
  [CONFIRM_USER_SUCCESS]: (state = {}, action) => {
    return { ...state };
  },
  [BANNED_USERS_SUCCESS]: (state = {}, action) => {
    return { ...state };
  },
  [UPDATE_USER_PASSWORD_SUCCESS]: (state = {}, action) => {
    return { ...state };
  },
  [CREATE_USERS_SUCCESS]: (state = {}, action) => {
    return { ...state };
  },
  [GET_USER_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, userDetail: action.payload.data };
  },
  [PUT_USER_SUCCESS]: (state = {}, action) => {
    return { ...state, userDetail: action.payload };
  },
};

export default function (state = userDataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
