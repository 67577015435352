import _ from 'lodash';
import {
  GET_APP_USER_COUPONS_SUCCESS,
  GET_APP_USER_COUPON_DETAIL_SUCCESS,
  CREATE_APP_USER_COUPON_SUCCESS,
  RESET_APP_USER_COUPON_DETAIL
} from '../actions/appUserCoupon';

export const dataInitialState = {
  appUserCoupons: {
    data: [],
    meta: {},
  },
  appUserCouponDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_APP_USER_COUPONS_SUCCESS]: (state = {}, action) => {
    const result = { ...state };
    const { data, meta } = action.payload;
    _.set(result, `appUserCoupons.data`, data);
    _.set(result, `appUserCoupons.meta`, meta);
    return result;
  },
  [GET_APP_USER_COUPON_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, appUserCouponDetail: action.payload.data };
  },
  [CREATE_APP_USER_COUPON_SUCCESS]: (state = {}, action) => {
    return {
      ...state,
      appUserCouponDetail: {
        ...state.appUserCouponDetail,
        ...action.payload.data,
      },
    };
  },
  [RESET_APP_USER_COUPON_DETAIL]: (state = {}, action) => {
    return { ...state, appUserCouponDetail: {} };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
