export const RECEIVED_UPDATE_USER = 'RECEIVED_UPDATE_USER';
export const CLEAN_UPDATE_USER = 'CLEAN_UPDATE_USER';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const CREATE_USERS_SUCCESS = 'CREATE_USERS_SUCCESS';
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS';

export function updateUser(data) {
  return {
    type: RECEIVED_UPDATE_USER,
    data,
  };
}

export function cleanUser() {
  return {
    type: CLEAN_UPDATE_USER,
  };
}

export function getUsers({ page = 1, pageSize = 10, isConfirmed = true }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_USERS_REQUEST'
    });
    return fetch(`/api/admin/v1/user?page=${page}&pageSize=${pageSize}&isConfirmed=${isConfirmed}` , {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        console.log('GET_USERS_REQUEST', result);
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_USERS_SUCCESS',
            payload: {
              ...jsonResult,
              isConfirmed,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_USERS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function createUsers({ users }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'CREATE_USERS_REQUEST'
    });
    return fetch(`/api/admin/v1/user`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          users
        }),
      })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'CREATE_USERS_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'CREATE_USERS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function getUserDetail({ id }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_USER_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/user/${id}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem("accessToken"),
        },
      })
      .then(async(result) => {
        console.log('GET_USER_DETAIL_REQUEST', result);
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_USER_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_USER_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}