import _ from 'lodash';
import {
  RESET_SHARING_BONUS_DETAIL,
  GET_SHARING_Bonuses_SUCCESS,
  GET_SHARING_BONUS_DETAIL_SUCCESS,
  CREATE_DRAFT_SHARING_BONUS_SUCCESS
} from '../actions/sharingBonus';


export const dataInitialState = {
  sharingBonusDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_SHARING_Bonuses_SUCCESS]: (state = {}, action) => {
    const result = JSON.parse(JSON.stringify(state));
    const { filterType, type, data, meta } = action.payload;
    _.set(result, `${filterType}.${type}.data`, data);
    _.set(result, `${filterType}.${type}.meta`, meta);
    return result;
  },
  [GET_SHARING_BONUS_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, sharingBonusDetail: action.payload.data };
  },
  [CREATE_DRAFT_SHARING_BONUS_SUCCESS]: (state = {}, action) => {
    return {
      ...state,
      sharingBonusDetail: {
        ...state.sharingBonusDetail,
        ...action.payload.data,
      },
    };
  },
  [RESET_SHARING_BONUS_DETAIL]: (state = {}, action) => {
    return { ...state, sharingBonusDetail: {} };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
