import _ from 'lodash';
import {
  GET_ANNOTATE_STATISTICS_SUCCESS,
  GET_ANNOTATE_STATISTICS_DETAIL_SUCCESS,
} from '../actions/annotateStatistics';


export const dataInitialState = {
  annotateStatistics: {
    data: [],
    meta: {},
  },
  annotateStatisticsDetail: {
    data: [],
    meta: {},
  },
};

export const ACTION_HANDLERS = {
  [GET_ANNOTATE_STATISTICS_SUCCESS]: (state = {}, action) => {
    const result = { ...state };
    const { data, meta } = action.payload;
    _.set(result, `annotateStatistics.data`, data);
    _.set(result, `annotateStatistics.meta`, meta);
    return result;
  },
  [GET_ANNOTATE_STATISTICS_DETAIL_SUCCESS]: (state = {}, action) => {
    const result = { ...state };
    const { data, meta } = action.payload;
    _.set(result, `annotateStatisticsDetail.data`, data);
    _.set(result, `annotateStatisticsDetail.meta`, meta);
    return result;
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
