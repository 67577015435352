import {
  GET_EVENT_SEQUENCE_SUCCESS,
  UPDATE_EVENT_SEQUENCE_SUCCESS,
} from '../actions/eventSequence';


export const dataInitialState = {
};

export const ACTION_HANDLERS = {
  [GET_EVENT_SEQUENCE_SUCCESS]: (state = {}, action) => {
    return { ...state, ...action.payload.data };
  },
  [UPDATE_EVENT_SEQUENCE_SUCCESS]: (state = {}, action) => {
    return { ...state, ...action.payload.data };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
