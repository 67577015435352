import { serialize } from '../utils/format';

export const RESET_PARTNER_DETAIL = 'RESET_PARTNER_DETAIL';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const GET_PARTNERS_HISTORY_SUCCESS = 'GET_PARTNERS_HISTORY_SUCCESS';
export const GET_PARTNER_DETAIL_SUCCESS = 'GET_PARTNER_DETAIL_SUCCESS';
export const CREATE_PARTNER_SUCCESS = 'CREATE_PARTNER_SUCCESS';
export const DELETED_PARTNER_SUCCESS = 'DELETED_PARTNER_SUCCESS';

export function resetPartnerDetail() {
  return {
    type: 'RESET_PARTNER_DETAIL',
  };
}

// const {
//  sort = {
//    startDate: -1,
//  },
//  page = 1,
//  pageSize = 20,
//  filterType
// } = data;
export function getPartners(data) {
  if (!data.page) data.page = 1;
  if (!data.pageSize) data.pageSize = 20;
  const query = serialize(data);
  const { filterType } = data;
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_PARTNERS_REQUEST'
    });
    return fetch(`/api/admin/v1/partner?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_PARTNERS_SUCCESS',
            payload: {
              ...jsonResult,
              filterType,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_PARTNERS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function getPartnerDetail({ id }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_PARTNER_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/partner/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_PARTNER_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_PARTNER_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function createPartner(data) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'CREATE_PARTNER_REQUEST'
    });
    return fetch(`/api/admin/v1/partner`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(data),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          dispatch(getPartnerDetail({
            id: jsonResult.data.id,
          }));
          return dispatch({
            type: 'CREATE_PARTNER_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'CREATE_PARTNER_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function deletedPartner({ id, cb }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'DELETED_PARTNER_REQUEST'
    });
    return fetch(`/api/admin/v1/partner/${id}`, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          cb();
          return dispatch({
            type: 'DELETED_PARTNER_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'DELETED_PARTNER_FAILURE',
        payload: error,
        error: true
      }));
  };
}

// const {
//  sort = {
//    startDate: -1,
//  },
//  page = 1,
//  pageSize = 20,
//  filterType
// } = data;
export function getPartnersHistory(data) {
  if (!data.page) data.page = 1;
  if (!data.pageSize) data.pageSize = 20;
  const query = serialize(data);
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_PARTNERS_HISTORY_REQUEST'
    });
    return fetch(`/api/admin/v1/partner/${data.id}/history?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_PARTNERS_HISTORY_SUCCESS',
            payload: {
              ...jsonResult,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_PARTNERS_HISTORY_FAILURE',
        payload: error,
        error: true
      }));
  };
}
