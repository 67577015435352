import {
  UPLOAD_FILE_SUCCESS
} from '../actions/fileUpload';


export const dataInitialState = {};

export const ACTION_HANDLERS = {
  [UPLOAD_FILE_SUCCESS]: (state = {}, action) => {
    const {data, key } = action.payload;
    const result = {
      ...state,
      [key]: data.storageId,
    };
    return result;
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
