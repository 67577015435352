import { serialize } from '../utils/format';

export const RESET_BLACKLIST_DETAIL = 'RESET_BLACKLIST_DETAIL';
export const GET_BLACKLISTS_SUCCESS = 'GET_BLACKLISTS_SUCCESS';
export const GET_BLACKLIST_DETAIL_SUCCESS = 'GET_BLACKLIST_DETAIL_SUCCESS';
export const CREATE_BLACKLIST_SUCCESS = 'CREATE_BLACKLIST_SUCCESS';
export const DELETED_BLACKLIST_SUCCESS = 'DELETED_BLACKLIST_SUCCESS';

export function resetBlacklistDetail() {
  return {
    type: 'RESET_BLACKLIST_DETAIL',
  };
}

// const {
//  sort = {
//    startDate: -1,
//  },
//  page = 1,
//  pageSize = 20,
// } = data;
export function getBlacklists(data) {
  if (!data.page) data.page = 1;
  if (!data.pageSize) data.pageSize = 20;
  const query = serialize(data);
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_BLACKLISTS_REQUEST'
    });
    return fetch(`/api/admin/v1/blacklist?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_BLACKLISTS_SUCCESS',
            payload: {
              ...jsonResult,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_BLACKLISTS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function getBlacklistDetail({ id }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_BLACKLIST_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/blacklist/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_BLACKLIST_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_BLACKLIST_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function createBlacklist({ data, cb }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'CREATE_BLACKLIST_REQUEST'
    });
    return fetch(`/api/admin/v1/blacklist`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(data),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          cb();
          return dispatch({
            type: 'CREATE_BLACKLIST_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'CREATE_BLACKLIST_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function deletedBlacklist({ id, cb }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'DELETED_BLACKLIST_REQUEST'
    });
    return fetch(`/api/admin/v1/blacklist/${id}`, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          cb();
          return dispatch({
            type: 'DELETED_BLACKLIST_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'DELETED_BLACKLIST_FAILURE',
        payload: error,
        error: true
      }));
  };
}