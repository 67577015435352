import { serialize } from '../utils/format';

export const GET_ANNOTATE_STATISTICS_SUCCESS = 'GET_ANNOTATE_STATISTICS_SUCCESS';
export const GET_ANNOTATE_STATISTICS_DETAIL_SUCCESS = 'GET_ANNOTATE_STATISTICS_DETAIL_SUCCESS';
// const {
  // userId,
  // keyword = '',
  // method = [],
  // status = [],
  // startDate,
  // endDate,
  // sort = {
  //   createdAt: -1,
  // },
  // page = 1,
  // pageSize = 20,
// } = data;
export function getAnnotateStatistics(data) {
  if (!data.page) data.page = 1;
  if (!data.pageSize) data.pageSize = 5;
  const query = serialize(data);
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_ANNOTATE_STATISTICS_REQUEST'
    });
    return fetch(`/api/admin/v1/annotate?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_ANNOTATE_STATISTICS_SUCCESS',
            payload: {
              ...jsonResult,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_ANNOTATE_STATISTICS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function getAnnotateStatisticsDetail(data) {
  if (!data.page) data.page = 1;
  if (!data.pageSize) data.pageSize = 20;
  const query = serialize(data);
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_ANNOTATE_STATISTICS_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/annotate/date?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_ANNOTATE_STATISTICS_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_ANNOTATE_STATISTICS_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}