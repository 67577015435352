import {
  GET_DASHBOARD_DETAIL_SUCCESS,
  RESET_DASHBOARD_DETAIL,
} from '../actions/dashboard';

export const dataInitialState = {
  dashboardDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_DASHBOARD_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, dashboardDetail: action.payload.data };
  },
  [RESET_DASHBOARD_DETAIL]: (state = {}, action) => {
    return { ...state, dashboardDetail: {} };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
