import _ from 'lodash';
import {
  RESET_MOOD_DETAIL,
  GET_MOODS_SUCCESS,
  GET_MOOD_DETAIL_SUCCESS,
  UPDATE_MOOD_SUCCESS,
} from '../actions/mood';


export const dataInitialState = {
  moodDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_MOODS_SUCCESS]: (state = {}, action) => {
    const result = JSON.parse(JSON.stringify(state));
    const { filterType, data, meta } = action.payload;
    _.set(result, `${filterType}.data`, data);
    _.set(result, `${filterType}.meta`, meta);
    return result;
  },
  [GET_MOOD_DETAIL_SUCCESS]: (state = {}, action) => {
    const { moodList } = action.payload.data;
    return {
      ...state,
      moodDetail: {
        ...action.payload.data,
        ...moodList ? { moodList: _.sortBy(moodList, ['order']) } : {},
      }
    };
  },
  [UPDATE_MOOD_SUCCESS]: (state = {}, action) => {
    const { moodList } = action.payload.data;
    return {
      ...state,
      moodDetail: {
        ...state.moodDetail,
        ...action.payload.data,
        ...moodList ? { moodList: _.sortBy(moodList, ['order']) } : {},
      },
    };
  },
  [RESET_MOOD_DETAIL]: (state = {}, action) => {
    return { ...state, moodDetail: {} };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
