import _ from 'lodash';
import {
  GET_PARTNER_USERS_SUCCESS,
  GET_PARTNER_USER_DETAIL_SUCCESS,
  CREATE_PARTNER_USER_SUCCESS,
  RESET_PARTNER_USER_DETAIL
} from '../actions/partnerUser';

export const dataInitialState = {
  partnerUsers: {
    data: [],
    meta: {},
  },
  partnerUserDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_PARTNER_USERS_SUCCESS]: (state = {}, action) => {
    const result = { ...state };
    const { data, meta } = action.payload;
    _.set(result, `partnerUsers.data`, data);
    _.set(result, `partnerUsers.meta`, meta);
    return result;
  },
  [GET_PARTNER_USER_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, partnerUserDetail: action.payload.data };
  },
  [CREATE_PARTNER_USER_SUCCESS]: (state = {}, action) => {
    return {
      ...state,
      partnerUserDetail: {
        ...state.partnerUserDetail,
        ...action.payload.data,
      },
    };
  },
  [RESET_PARTNER_USER_DETAIL]: (state = {}, action) => {
    return { ...state, partnerUserDetail: {} };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
