import _ from 'lodash';
import {
  GET_STORY_HELPS_SUCCESS,
  GET_STORY_HELP_DETAIL_SUCCESS,
  CREATE_STORY_HELP_SUCCESS,
  RESET_STORY_HELP_DETAIL
} from '../actions/storyHelp';

export const dataInitialState = {
  storyHelps: {
    data: [],
    meta: {},
  },
  storyHelpDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_STORY_HELPS_SUCCESS]: (state = {}, action) => {
    const result = { ...state };
    const { data, meta, category } = action.payload;
    _.set(result, `storyHelps.${category}.data`, data);
    _.set(result, `storyHelps.${category}.meta`, meta);
    return result;
  },
  [GET_STORY_HELP_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, storyHelpDetail: action.payload.data };
  },
  [CREATE_STORY_HELP_SUCCESS]: (state = {}, action) => {
    return {
      ...state,
      storyHelpDetail: {
        ...state.storyHelpDetail,
        ...action.payload.data,
      },
    };
  },
  [RESET_STORY_HELP_DETAIL]: (state = {}, action) => {
    return { ...state, storyHelpDetail: {} };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
