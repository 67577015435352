import _ from 'lodash';
import {
  UPDATE_ADMIN_NAV,
  UPDATE_GOD_NAV,
  RESET_NAV,
  UPDATE_PARTNER_NAV,
  UPDATE_ANNOTATE_PARTNER_NAV,
  UPDATE_ADMIN_ANNOTATE_PARTNER_NAV
} from '../actions/navigation';


export const dataInitialState = {
  items: [],
}

export const adminDataInitialState = {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'cui-speedometer',
    },
    {
      name: '故事列表',
      url: '/story',
      icon: 'cui-comment-square',
    },
    {
      name: '舉報列表',
      url: '/report',
      icon: 'icon-layers',
    },
    {
      name: '公告',
      url: '/announcement',
      icon: 'icon-direction',
    },
    {
      name: '活動列表',
      url: '/event',
      icon: 'icon-rocket',
    },
    {
      name: '回饋',
      url: '/feedback',
      icon: 'icon-support',
    }, 
    {
      name: '諮詢地點',
      url: '/poi',
      icon: 'icon-map',
    }, 
    {
      name: '合作',
      icon: 'icon-people',
      children:[{
        name: '合作夥伴',
        url: '/partner',
        icon: 'icon-people',
      }, {
        name: '標記心理師',
        url: '/annotate/user',
        icon: 'icon-people',
      }]
    }, 
    {
      name: '關鍵字黑名單',
      url: '/blacklist',
      icon: 'icon-shield',
    },
    {
      name: '說心聲設定',
      icon: 'icon-speech',
      children: [{
        name: '情緒設定',
        url: '/mood',
        icon: 'icon-puzzle',
      }, {
        name: '故事引導設定',
        url: '/storyHelp',
        icon: 'icon-list',
      }, {
        name: '分享回饋設定',
        url: '/sharingBonus',
        icon: 'icon-trophy',
      }]
    }, 
    {
      name: '標註',
      icon: 'icon-chemistry',
      children: [{
        name: '標註故事',
        url: '/story/annotate',
        icon: 'cui-comment-square',
      },
      {
        name: '標註紀錄',
        url: '/annotate/statistics',
        icon: 'icon-book-open',
      }]
    }, 
    {
      name: '設定檔',
      url: '/config',
      icon: 'icon-layers',
      children: [{
        name: '版號管理',
        url: '/config/version',
        icon: 'icon-layers',
      }]
    }, 
    // {
    //   name: '頁面管理',
    //   url: '/pageSetting',
    //   icon: 'icon-layers',
    //   children: [{
    //     name: '產品頁',
    //     url: '/pageSetting/product',
    //     icon: 'icon-home',
    //   }]
    // }, 
    ...process.env.REACT_APP_SHOW_SANDBOX ===  '1' ? [{
      name: 'SandBox',
      url: '/sandbox',
      icon: 'icon-puzzle',
      children: [
        {
          name: 'Dashboard',
          url: '/dashboard',
          icon: 'icon-speedometer',
          badge: {
            variant: 'info',
            text: 'NEW',
          },
        }, {
          title: true,
          name: 'Theme',
          wrapper: { // optional wrapper object
            element: '', // required valid HTML5 element tag
            attributes: {} // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
          },
          class: '' // optional class names space delimited list for title item ex: "text-center"
        }, {
          name: 'Colors',
          url: '/theme/colors',
          icon: 'icon-drop',
        }, {
          name: 'Typography',
          url: '/theme/typography',
          icon: 'icon-pencil',
        }, {
          title: true,
          name: 'Components',
          wrapper: {
            element: '',
            attributes: {},
          },
        }, {
          name: 'Base',
          url: '/base',
          icon: 'icon-puzzle',
          children: [{
              name: 'Breadcrumbs',
              url: '/base/breadcrumbs',
              icon: 'icon-puzzle',
            },
            {
              name: 'Cards',
              url: '/base/cards',
              icon: 'icon-puzzle',
            },
            {
              name: 'Carousels',
              url: '/base/carousels',
              icon: 'icon-puzzle',
            },
            {
              name: 'Collapses',
              url: '/base/collapses',
              icon: 'icon-puzzle',
            },
            {
              name: 'Dropdowns',
              url: '/base/dropdowns',
              icon: 'icon-puzzle',
            },
            {
              name: 'Forms',
              url: '/base/forms',
              icon: 'icon-puzzle',
            },
            {
              name: 'Jumbotrons',
              url: '/base/jumbotrons',
              icon: 'icon-puzzle',
            },
            {
              name: 'List groups',
              url: '/base/list-groups',
              icon: 'icon-puzzle',
            },
            {
              name: 'Navs',
              url: '/base/navs',
              icon: 'icon-puzzle',
            },
            {
              name: 'Paginations',
              url: '/base/paginations',
              icon: 'icon-puzzle',
            },
            {
              name: 'Popovers',
              url: '/base/popovers',
              icon: 'icon-puzzle',
            },
            {
              name: 'Progress Bar',
              url: '/base/progress-bar',
              icon: 'icon-puzzle',
            },
            {
              name: 'Switches',
              url: '/base/switches',
              icon: 'icon-puzzle',
            },
            {
              name: 'Tables',
              url: '/base/tables',
              icon: 'icon-puzzle',
            },
            {
              name: 'Tabs',
              url: '/base/tabs',
              icon: 'icon-puzzle',
            },
            {
              name: 'Tooltips',
              url: '/base/tooltips',
              icon: 'icon-puzzle',
            },
          ],
        }, {
          name: 'Buttons',
          url: '/buttons',
          icon: 'icon-cursor',
          children: [{
              name: 'Buttons',
              url: '/buttons/buttons',
              icon: 'icon-cursor',
            },
            {
              name: 'Button dropdowns',
              url: '/buttons/button-dropdowns',
              icon: 'icon-cursor',
            },
            {
              name: 'Button groups',
              url: '/buttons/button-groups',
              icon: 'icon-cursor',
            },
            {
              name: 'Brand Buttons',
              url: '/buttons/brand-buttons',
              icon: 'icon-cursor',
            },
          ],
        }, {
          name: 'Charts',
          url: '/charts',
          icon: 'icon-pie-chart',
        }, {
          name: 'Icons',
          url: '/icons',
          icon: 'icon-star',
          children: [{
              name: 'CoreUI Icons',
              url: '/icons/coreui-icons',
              icon: 'icon-star',
              badge: {
                variant: 'info',
                text: 'NEW',
              },
            },
            {
              name: 'Flags',
              url: '/icons/flags',
              icon: 'icon-star',
            },
            {
              name: 'Font Awesome',
              url: '/icons/font-awesome',
              icon: 'icon-star',
              badge: {
                variant: 'secondary',
                text: '4.7',
              },
            },
            {
              name: 'Simple Line Icons',
              url: '/icons/simple-line-icons',
              icon: 'icon-star',
            },
          ],
        }, {
          name: 'Notifications',
          url: '/notifications',
          icon: 'icon-bell',
          children: [{
              name: 'Alerts',
              url: '/notifications/alerts',
              icon: 'icon-bell',
            },
            {
              name: 'Badges',
              url: '/notifications/badges',
              icon: 'icon-bell',
            },
            {
              name: 'Modals',
              url: '/notifications/modals',
              icon: 'icon-bell',
            },
          ],
        }, {
          name: 'Widgets',
          url: '/widgets',
          icon: 'icon-calculator',
          badge: {
            variant: 'info',
            text: 'NEW',
          },
        }, {
          divider: true,
        }, {
          title: true,
          name: 'Extras',
        }, {
          name: 'Pages',
          url: '/pages',
          icon: 'icon-star',
          children: [{
              name: 'Login',
              url: '/login',
              icon: 'icon-star',
            },
            {
              name: 'Register',
              url: '/register',
              icon: 'icon-star',
            },
            {
              name: 'Error 404',
              url: '/404',
              icon: 'icon-star',
            },
            {
              name: 'Error 500',
              url: '/500',
              icon: 'icon-star',
            },
          ],
        }, {
          name: 'Disabled',
          url: '/dashboard',
          icon: 'icon-ban',
          attributes: {
            disabled: true
          },
        }, {
          name: 'Download CoreUI',
          url: 'https://coreui.io/react/',
          icon: 'icon-cloud-download',
          class: 'mt-auto',
          variant: 'success',
          attributes: {
            target: '_blank',
            rel: "noopener"
          },
        }, {
          name: 'Try CoreUI PRO',
          url: 'https://coreui.io/pro/react/',
          icon: 'icon-layers',
          variant: 'danger',
          attributes: {
            target: '_blank',
            rel: "noopener"
          },
        },

      ]
    }] : [],
  ],
};

export const ACTION_HANDLERS = {
  [UPDATE_ADMIN_NAV]: (state = {}, action) => {
    let newItems = JSON.parse(JSON.stringify(state.items));
    if (_.findIndex(newItems, { 'url': '/story' }) === -1) {
      newItems = _.concat(newItems, adminDataInitialState.items);
    }
    const result = {
      items: newItems,
    };
    return result;
  },
  [UPDATE_GOD_NAV]: (state = {}, action) => {
    let newItems = JSON.parse(JSON.stringify(state.items));
    if (_.findIndex(newItems, { 'url': '/log' }) === -1) {
      newItems.push({
        name: '操作記錄',
        url: '/log',
        icon: 'icon-book-open',
      });
    }
    if (_.findIndex(newItems, { 'url': '/user' }) === -1) {
      newItems.push({
        name: '管理帳戶',
        url: '/user',
        icon: 'icon-user',
      });
    }
    const result = {
      items: newItems,
    };
    return result;
  },
  [UPDATE_PARTNER_NAV]: (state = {}, action) => {
    const { partnerId } = action;
    let newItems = JSON.parse(JSON.stringify(state.items));
    if (_.findIndex(newItems, { 'url': '/appUser/partner' }) === -1) {
      newItems.push({
        name: '優惠券',
        url: `/appUser/partner/${partnerId}/coupon`,
        icon: 'icon-book-open',
      });
    }
    const result = {
      items: newItems,
    };
    return result;
  },
  [UPDATE_ADMIN_ANNOTATE_PARTNER_NAV]: (state = {}, action) => {
    let newItems = JSON.parse(JSON.stringify(state.items));
    if (_.findIndex(newItems, { 'url': '/annotate/user' }) === -1) {
      newItems.push({
        name: '標記心理師',
        url: '/annotate/user',
        icon: 'icon-people',
      });
    }
    const result = {
      items: newItems,
    };
    return result;
  },
  [UPDATE_ANNOTATE_PARTNER_NAV]: (state = {}, action) => {
    let newItems = JSON.parse(JSON.stringify(state.items));
    if (_.findIndex(newItems, { 'url': '/story/annotate' }) === -1) {
      newItems.push({
        name: '標註故事',
        url: '/story/annotate',
        icon: 'cui-comment-square',
      });
      newItems.push({
        name: '標註紀錄',
        url: '/annotate/statistics',
        icon: 'icon-book-open',
      });
    }
    const result = {
      items: newItems,
    };
    return result;
  },
  [RESET_NAV]: (state = {}, action) => {
    return {
      ...dataInitialState,
    };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
