const _ = require('lodash');

export const GET_EVENT_SEQUENCE_SUCCESS = 'GET_EVENT_SEQUENCE_SUCCESS';
export const UPDATE_EVENT_SEQUENCE_SUCCESS = 'UPDATE_EVENT_SEQUENCE_SUCCESS';
export const MOVE_SEQUENCE_UP = 'MOVE_SEQUENCE_UP';
export const MOVE_SEQUENCE_DOWN = 'MOVE_SEQUENCE_DOWN';

export function getEventSequence() {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_EVENT_SEQUENCES_REQUEST'
    });
    return fetch(`/api/admin/v1/eventSequence`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_EVENT_SEQUENCE_SUCCESS',
            payload: {
              ...jsonResult,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_EVENT_SEQUENCES_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function updateEventSequence({ data }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'CREATE_EVENT_SEQUENCE_REQUEST'
    });
    return fetch(`/api/admin/v1/eventSequence`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(data),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'UPDATE_EVENT_SEQUENCE_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'CREATE_EVENT_SEQUENCE_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function moveSequenceUp({ type, category }) {
  return async(dispatch, getState) => {
    const { eventSequence } = getState();
    const categoryData = _.get(eventSequence, category);
    let target1 = _.find(categoryData, { type });
    let target2 = _.find(categoryData, { order: target1.order - 1 });
    const updateTarget1 = {
      ...target1,
      order: target1.order - 1
    };
    const updateTarget2 = {
      ...target2,
      order: target2.order + 1
    };
    return dispatch(updateEventSequence({
      data: {
        sequence: [updateTarget1, updateTarget2]
      },
    }));
  };
}

export function moveSequenceDown({ type, category }) {
  return async(dispatch, getState) => {
    const { eventSequence } = getState();
    const categoryData = _.get(eventSequence, category);
    let target1 = _.find(categoryData, { type });
    let target2 = _.find(categoryData, { order: target1.order + 1 });
    const updateTarget1 = {
      ...target1,
      order: target1.order + 1
    };
    const updateTarget2 = {
      ...target2,
      order: target2.order - 1
    };
    return dispatch(updateEventSequence({
      data: {
        sequence: [updateTarget1, updateTarget2]
      },
    }));
  };
}
