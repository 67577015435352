import {
  RESET_PAGESETTING_DETAIL,
  GET_PAGESETTING_DETAIL_SUCCESS,
} from '../actions/pageSetting';


export const dataInitialState = {
  pageSettingDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_PAGESETTING_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, pageSettingDetail: action.payload.data };
  },
  [RESET_PAGESETTING_DETAIL]: (state = {}, action) => {
    return { ...state, pageSettingDetail: { pageSettingHistory: {} } };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
