import { serialize } from '../utils/format';

export const RESET_APP_USER_COUPON_DETAIL = 'RESET_APP_USER_COUPON_DETAIL';
export const GET_APP_USER_COUPONS_SUCCESS = 'GET_APP_USER_COUPONS_SUCCESS';
export const GET_APP_USER_COUPON_DETAIL_SUCCESS = 'GET_APP_USER_COUPON_DETAIL_SUCCESS';
export const CREATE_APP_USER_COUPON_SUCCESS = 'CREATE_APP_USER_COUPON_SUCCESS';
export const DELETED_APP_USER_COUPON_SUCCESS = 'DELETED_APP_USER_COUPON_SUCCESS';

export function resetAppUserCouponDetail() {
  return {
    type: 'RESET_APP_USER_COUPON_DETAIL',
  };
}

export function getAppUserCoupons(data) {
  if (!data.page) data.page = 1;
  if (!data.pageSize) data.pageSize = 20;
  const query = serialize(data);
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_APP_USER_COUPONS_REQUEST'
    });
    return fetch(`/api/admin/v1/appUser/coupon?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_APP_USER_COUPONS_SUCCESS',
            payload: {
              ...jsonResult,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_APP_USER_COUPONS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function getAppUserCouponDetail({ id }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_APP_USER_COUPON_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/appUser/coupon/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_APP_USER_COUPON_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_APP_USER_COUPON_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function createAppUserCoupon(data) {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    if (key !== 'cb') {
      formData.append(key, data[key]);
    }
  })
  return async(dispatch, getState) => {
    dispatch({
      type: 'CREATE_APP_USER_COUPON_REQUEST'
    });
    return fetch(`/api/admin/v1/appUser/coupon`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        // 'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: formData,
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          if (data.cb) data.cb()
          return dispatch({
            type: 'CREATE_APP_USER_COUPON_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'CREATE_APP_USER_COUPON_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function deletedAppUserCoupon({ id, cb }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'DELETED_APP_USER_COUPON_REQUEST'
    });
    return fetch(`/api/admin/v1/appUser/coupon/${id}`, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          cb();
          return dispatch({
            type: 'DELETED_APP_USER_COUPON_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'DELETED_APP_USER_COUPON_FAILURE',
        payload: error,
        error: true
      }));
  };
}