import { serialize } from '../utils/format';

export const RESET_EVENT_DETAIL = 'RESET_EVENT_DETAIL';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENT_DETAIL_SUCCESS = 'GET_EVENT_DETAIL_SUCCESS';
export const CREATE_DRAFT_EVENT_SUCCESS = 'CREATE_DRAFT_EVENT_SUCCESS';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const DELETED_EVENT_SUCCESS = 'DELETED_EVENT_SUCCESS';
export const PUT_EVENT_ORDER_SUCCESS = 'PUT_EVENT_ORDER_SUCCESS';

export function resetEventDetail() {
  return {
    type: 'RESET_EVENT_DETAIL',
  };
}

// const {
//  startDate,
//  endDate,
//  state = 1,
//  sort = {
//    startDate: -1,
//  },
//  page = 1,
//  pageSize = 20,
// } = data;
export function getEvents(data) {
  if (!data.page) data.page = 1;
  if (!data.pageSize) data.pageSize = 20;
  const query = serialize(data);
  const { filterType, category, type } = data;
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_EVENTS_REQUEST'
    });
    return fetch(`/api/admin/v1/event?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_EVENTS_SUCCESS',
            payload: {
              ...jsonResult,
              filterType,
              category,
              type,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_EVENTS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function getEventDetail({ id }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_EVENT_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/event/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_EVENT_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_EVENT_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function createDraftEvent(data) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'CREATE_DRAFT_EVENT_REQUEST'
    });
    return fetch(`/api/admin/v1/event/draft`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(data),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'CREATE_DRAFT_EVENT_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'CREATE_DRAFT_EVENT_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function createEvent({ data, cb }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'CREATE_EVENT_REQUEST'
    });
    return fetch(`/api/admin/v1/event`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(data),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          cb();
          return dispatch({
            type: 'CREATE_EVENT_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'CREATE_EVENT_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function deletedEvent({ id, cb }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'DELETED_EVENT_REQUEST'
    });
    return fetch(`/api/admin/v1/event/${id}`, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          cb();
          return dispatch({
            type: 'DELETED_EVENT_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'DELETED_EVENT_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function putEventOrder({ event, cb }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'PUT_EVENT_ORDER_REQUEST'
    });
    return fetch(`/api/admin/v1/event/order`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({ event }),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          cb();
          return dispatch({
            type: 'PUT_EVENT_ORDER_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'PUT_EVENT_ORDER_FAILURE',
        payload: error,
        error: true
      }));
  };
}