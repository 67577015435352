import _ from 'lodash';
import {
  RESET_BLACKLIST_DETAIL,
  GET_BLACKLISTS_SUCCESS,
  GET_BLACKLIST_DETAIL_SUCCESS,
  CREATE_BLACKLIST_SUCCESS
} from '../actions/blacklist';


export const dataInitialState = {
  blacklists: {
    data: [],
    meta: {},
  },
  blacklistDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_BLACKLISTS_SUCCESS]: (state = {}, action) => {
    const result = { ...state };
    const { data, meta } = action.payload;
    _.set(result, `blacklists.data`, data);
    _.set(result, `blacklists.meta`, meta);
    return result;
  },
  [GET_BLACKLIST_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, blacklistDetail: action.payload.data };
  },
  [CREATE_BLACKLIST_SUCCESS]: (state = {}, action) => {
    return {
      ...state,
      blacklistDetail: {
        ...state.blacklistDetail,
        ...action.payload.data,
      },
    };
  },
  [RESET_BLACKLIST_DETAIL]: (state = {}, action) => {
    return { ...state, blacklistDetail: {} };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
