export const RESET_PAGESETTING_DETAIL = 'RESET_PAGESETTING_DETAIL';
export const GET_PAGESETTING_DETAIL_SUCCESS = 'GET_PAGESETTING_DETAIL_SUCCESS';
export const UPDATE_PAGESETTING_SUCCESS = 'UPDATE_PAGESETTING_SUCCESS';

export function resetPageSettingDetail() {
  return {
    type: 'RESET_PAGESETTING_DETAIL',
  };
}

export function getPageSettingDetail({ key }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_PAGESETTING_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/pageSetting/${key}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_PAGESETTING_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_PAGESETTING_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function updatePageSetting({ data, key }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'UPDATE_PAGESETTING_REQUEST'
    });
    return fetch(`/api/admin/v1/pageSetting/${key}`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({ ...data }),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          dispatch(getPageSettingDetail({
            key: jsonResult.data.key,
          }));
          return dispatch({
            type: 'UPDATE_PAGESETTING_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'UPDATE_PAGESETTING_FAILURE',
        payload: error,
        error: true
      }));
  };
}