import _ from 'lodash';
import {
  RESET_PARTNER_DETAIL,
  GET_PARTNERS_SUCCESS,
  GET_PARTNER_DETAIL_SUCCESS,
  CREATE_PARTNER_SUCCESS,
  GET_PARTNERS_HISTORY_SUCCESS
} from '../actions/partner';


export const dataInitialState = {
  partners: {
    data: [],
    meta: {},
  },
  partnerDetail: {
    partnerHistory: {
      data: [],
      meta: {},
    },
  },
};

export const ACTION_HANDLERS = {
  [GET_PARTNERS_SUCCESS]: (state = {}, action) => {
    const result = { ...state };
    const { filterType, data, meta } = action.payload;
    _.set(result, `${filterType}.data`, data);
    _.set(result, `${filterType}.meta`, meta);
    return result;
  },
  [GET_PARTNER_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, partnerDetail: action.payload.data };
  },
  [CREATE_PARTNER_SUCCESS]: (state = {}, action) => {
    return {
      ...state,
      partnerDetail: {
        ...state.partnerDetail,
        ...action.payload.data,
      },
    };
  },
  [RESET_PARTNER_DETAIL]: (state = {}, action) => {
    return { ...state, partnerDetail: { partnerHistory: {} } };
  },
  [GET_PARTNERS_HISTORY_SUCCESS]: (state = {}, action) => {
    const result = _.cloneDeep(state);
    const { data, meta } = action.payload;
    _.set(result, `partnerDetail.partnerHistory.data`, data);
    _.set(result, `partnerDetail.partnerHistory.meta`, meta);
    return result;
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
