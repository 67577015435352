import _ from 'lodash';
import {
  GET_STORY_ANNOTATES_SUCCESS,
  GET_STORY_ANNOTATE_DETAIL_SUCCESS,
  RESET_STORY_ANNOTATE_DETAIL,
  // CREATE_STORY_ANNOTATE_SUCCESS,
  DELETED_STORY_ANNOTATE_SUCCESS,
} from '../actions/storyAnnotate';


export const dataInitialState = {
  storyAnnotateDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_STORY_ANNOTATES_SUCCESS]: (state = {}, action) => {
    const result = { ...state };
    const { data, meta } = action.payload;
    _.set(result, `state${action.payload.annotateState}.data`, data);
    _.set(result, `state${action.payload.annotateState}.meta`, meta);
    return result;
  },
  [GET_STORY_ANNOTATE_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, storyAnnotateDetail: action.payload.data };
  },
  // [CREATE_STORY_ANNOTATE_SUCCESS]: (state = {}, action) => {
  //   return {
  //     ...state,
  //     poiDetail: {
  //       ...state.poiDetail,
  //       ...action.payload.data,
  //     },
  //   };
  // },
  [RESET_STORY_ANNOTATE_DETAIL]: (state = {}, action) => {
    return { ...state, storyAnnotateDetail: {} };
  },
  [DELETED_STORY_ANNOTATE_SUCCESS]: (state = {}, action) => {
    return { ...state, storyAnnotateDetail: {} };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
