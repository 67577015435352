export const serialize = (obj, prefix) => {
  let str = [];
  for(let p in obj) {
    if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
      let k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
      str.push(typeof v == "object" ?
        serialize(v, k) :
        encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  str = str.filter(o => !!o);
  return str.join("&");
}