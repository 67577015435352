/* eslint global-require: 0 */

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

// import * as actionCreators from './actions/counter';

let composeEnhancers = compose;

composeEnhancers = (composeWithDevTools)({
  trace: true,
});

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
);

export default function configureStore(initialState) {
  const store = createStore(
    reducer,
    initialState,
    enhancer
  );
  if (module.hot) {
    module.hot.accept(() => {
      store.replaceReducer(require('./reducers').default);
    });
  }
  return store;
}
