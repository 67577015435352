import { serialize } from '../utils/format';

export const RESET_FEEDBACK_DETAIL = 'RESET_FEEDBACK_DETAIL';
export const GET_FEEDBACKS_SUCCESS = 'GET_FEEDBACKS_SUCCESS';
export const EXPORT_FEEDBACKS_SUCCESS = 'EXPORT_FEEDBACKS_SUCCESS';
export const GET_FEEDBACK_DETAIL_SUCCESS = 'GET_FEEDBACK_DETAIL_SUCCESS';
export const ADD_FEEDBACK_REMARK_SUCCESS = 'ADD_FEEDBACK_REMARK_SUCCESS';
export const SOLVE_FEEDBACK_SUCCESS = 'SOLVE_FEEDBACK_SUCCESS';

export function resetFeedbackDetail() {
  return {
    type: 'RESET_FEEDBACK_DETAIL',
  };
}

// const {
//   keyword = '',
//   state,
//   reason,
//   startDate,
//   endDate,
//   sort = {
//     createdAt: -1,
//   },
//   page = 1,
//   pageSize = 5,
// } = data;
export function getFeedbacks(data) {
  if (!data.page) data.page = 1;
  if (!data.pageSize) data.pageSize = 20;
  const query = serialize(data);
  const { state } = data;
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_FEEDBACKS_REQUEST'
    });
    return fetch(`/api/admin/v1/feedback?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_FEEDBACKS_SUCCESS',
            payload: {
              ...jsonResult,
              state,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_FEEDBACKS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function exportFeedbackList(data) {
  data.page = 0;
  const query = serialize(data);
  return async(dispatch, getState) => {
    dispatch({
      type: 'EXPORT_FEEDBACKS_REQUEST'
    });
    return fetch(`/api/admin/v1/feedback/export?${query}`, {
      headers: {
        // Accept: 'application/json',
        // 'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {

        if (result.ok) {
          const blob = await result.blob()
          let filename = result.headers.get("content-disposition");
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(filename);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          const jsonResult = await result.json();
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'EXPORT_FEEDBACKS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function getFeedbackDetail({ id }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_FEEDBACK_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/feedback/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_FEEDBACK_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_FEEDBACK_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function addFeedbackRemark({ id, message }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'ADD_FEEDBACK_REMARK_REQUEST'
    });
    return fetch(`/api/admin/v1/feedback/${id}/remark`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({ message }),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          dispatch(getFeedbackDetail({ id }))
          return dispatch({
            type: 'ADD_FEEDBACK_REMARK_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'ADD_FEEDBACK_REMARK_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function solveFeedback({ id, cb }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'SOLVE_FEEDBACK_REQUEST'
    });
    return fetch(`/api/admin/v1/feedback/${id}/solve`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          if (cb) cb();
          return dispatch({
            type: 'SOLVE_FEEDBACK_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'SOLVE_FEEDBACK_FAILURE',
        payload: error,
        error: true
      }));
  };
}