import { serialize } from '../utils/format';

export const RESET_STORY_HELP_DETAIL = 'RESET_STORY_HELP_DETAIL';
export const GET_STORY_HELPS_SUCCESS = 'GET_STORY_HELPS_SUCCESS';
export const GET_STORY_HELP_DETAIL_SUCCESS = 'GET_STORY_HELP_DETAIL_SUCCESS';
export const CREATE_STORY_HELP_SUCCESS = 'CREATE_STORY_HELP_SUCCESS';
export const DELETED_STORY_HELP_SUCCESS = 'DELETED_STORY_HELP_SUCCESS';

export function resetStoryHelpDetail() {
  return {
    type: 'RESET_STORY_HELP_DETAIL',
  };
}

export function getStoryHelps(data) {
  if (!data.page) data.page = 1;
  if (!data.pageSize) data.pageSize = 10;
  const query = serialize(data);
  const { category } = data;
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_STORY_HELPS_REQUEST'
    });
    return fetch(`/api/admin/v1/storyHelp?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_STORY_HELPS_SUCCESS',
            payload: {
              ...jsonResult,
              category,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_STORY_HELPS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function getStoryHelpDetail({ id }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_STORY_HELP_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/storyHelp/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_STORY_HELP_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_STORY_HELP_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function createStoryHelp(data) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'CREATE_STORY_HELP_REQUEST'
    });
    return fetch(`/api/admin/v1/storyHelp`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(data),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'CREATE_STORY_HELP_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'CREATE_STORY_HELP_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function deletedStoryHelp({ id, cb }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'DELETED_STORY_HELP_REQUEST'
    });
    return fetch(`/api/admin/v1/storyHelp/${id}`, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          cb();
          return dispatch({
            type: 'DELETED_STORY_HELP_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'DELETED_STORY_HELP_FAILURE',
        payload: error,
        error: true
      }));
  };
}