import _ from 'lodash';
import {
  GET_LOGS_SUCCESS,
  GET_LOG_DETAIL_SUCCESS,
} from '../actions/log';


export const dataInitialState = {
  logs: {
    data: [],
    meta: {},
  },
  logDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_LOGS_SUCCESS]: (state = {}, action) => {
    const result = { ...state };
    const { data, meta } = action.payload;
    _.set(result, `logs.data`, data);
    _.set(result, `logs.meta`, meta);
    return result;
  },
  [GET_LOG_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, logDetail: action.payload.data };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
