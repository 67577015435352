import { serialize } from '../utils/format';

export const RESET_SHARING_BONUS_DETAIL = 'RESET_SHARING_BONUS_DETAIL';
export const GET_SHARING_Bonuses_SUCCESS = 'GET_SHARING_Bonuses_SUCCESS';
export const GET_SHARING_BONUS_DETAIL_SUCCESS = 'GET_SHARING_BONUS_DETAIL_SUCCESS';
export const CREATE_DRAFT_SHARING_BONUS_SUCCESS = 'CREATE_DRAFT_SHARING_BONUS_SUCCESS';
export const CREATE_SHARING_BONUS_SUCCESS = 'CREATE_SHARING_BONUS_SUCCESS';
export const DELETED_SHARING_BONUS_SUCCESS = 'DELETED_SHARING_BONUS_SUCCESS';
export const PUT_SHARING_BONUS_ORDER_SUCCESS = 'PUT_SHARING_BONUS_ORDER_SUCCESS';

export function resetSharingBonusDetail() {
  return {
    type: 'RESET_SHARING_BONUS_DETAIL',
  };
}

// const {
//  startDate,
//  endDate,
//  state = 1,
//  sort = {
//    startDate: -1,
//  },
//  page = 1,
//  pageSize = 20,
// } = data;
export function getSharingBonuses(data) {
  if (!data.page) data.page = 1;
  if (!data.pageSize) data.pageSize = 20;
  const query = serialize(data);
  const { filterType, type } = data;
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_SHARING_Bonuses_REQUEST'
    });
    return fetch(`/api/admin/v1/sharingBonus?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_SHARING_Bonuses_SUCCESS',
            payload: {
              ...jsonResult,
              filterType,
              type,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_SHARING_Bonuses_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function getSharingBonusDetail({ id }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_SHARING_BONUS_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/sharingBonus/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_SHARING_BONUS_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_SHARING_BONUS_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function createDraftSharingBonus(data) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'CREATE_DRAFT_SHARING_BONUS_REQUEST'
    });
    return fetch(`/api/admin/v1/sharingBonus/draft`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(data),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'CREATE_DRAFT_SHARING_BONUS_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'CREATE_DRAFT_SHARING_BONUS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function createSharingBonus({ data, cb }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'CREATE_SHARING_BONUS_REQUEST'
    });
    return fetch(`/api/admin/v1/sharingBonus`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(data),
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          cb();
          return dispatch({
            type: 'CREATE_SHARING_BONUS_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'CREATE_SHARING_BONUS_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function deletedSharingBonus({ id, cb }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'DELETED_SHARING_BONUS_REQUEST'
    });
    return fetch(`/api/admin/v1/sharingBonus/${id}`, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          cb();
          return dispatch({
            type: 'DELETED_SHARING_BONUS_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'DELETED_SHARING_BONUS_FAILURE',
        payload: error,
        error: true
      }));
  };
}
