import { serialize } from '../utils/format';

export const GET_STORIES_SUCCESS = 'GET_STORIES_SUCCESS';
export const GET_STORY_DETAIL_SUCCESS = 'GET_STORY_DETAIL_SUCCESS';
export const BANNED_STORY_SUCCESS = 'BANNED_STORY_SUCCESS';
export const RESTORE_STORY_SUCCESS = 'RESTORE_STORY_SUCCESS';

// const {
//   page = 1,
//   pageSize = 20,
//   state,
//   category,
//   emotion,
//   startDate,
//   endDate,
//   sort,
//   uid,
// } = data;
export function getStories(data) {
  if (!data.page) data.page = 1;
  if (!data.pageSize) data.pageSize = 20;
  const query = serialize(data);
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_STORIES_REQUEST'
    });
    return fetch(`/api/admin/v1/story?${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          const splitAuthorization = localStorage.getItem("accessToken").split(' ');
          const token = splitAuthorization[1];
          const data = jsonResult.data.map((o) => {
            return {
              ...o,
              audioUrl: `${o.audioUrl}?token=${token}`
            };
          })
          return dispatch({
            type: 'GET_STORIES_SUCCESS',
            payload: {
              ...jsonResult,
              data,
            },
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_STORIES_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function getStoryDetail({ uid }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_STORY_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/story/${uid}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_STORY_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_STORY_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function bannedStory({ uid, cb }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'BANNED_STORY_REQUEST'
    });
    return fetch(`/api/admin/v1/story/${uid}/banned`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          cb();
          return dispatch({
            type: 'BANNED_STORY_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'BANNED_STORY_FAILURE',
        payload: error,
        error: true
      }));
  };
}

export function restoreStory({ uid }) {
  return async(dispatch, getState) => {
    dispatch({
      type: 'RESTORE_STORY_REQUEST'
    });
    return fetch(`/api/admin/v1/story/${uid}/restore`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          dispatch(getStoryDetail({ uid }));
          return dispatch({
            type: 'RESTORE_STORY_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'RESTORE_STORY_FAILURE',
        payload: error,
        error: true
      }));
  };
}