export const UPDATE_ADMIN_NAV = 'UPDATE_ADMIN_NAV';
export const UPDATE_GOD_NAV = 'UPDATE_GOD_NAV';
export const UPDATE_PARTNER_NAV = 'UPDATE_PARTNER_NAV';
export const UPDATE_ANNOTATE_PARTNER_NAV = 'UPDATE_ANNOTATE_PARTNER_NAV';
export const UPDATE_ADMIN_ANNOTATE_PARTNER_NAV = 'UPDATE_ADMIN_ANNOTATE_PARTNER_NAV';
export const RESET_NAV = 'RESET_NAV';

export function resetNav() {
  return (dispatch) => {
    return dispatch({
      type: RESET_NAV,
    });
  }
}
export function updateGodNav() {
  return (dispatch, state) => {
    const { auth } = state();
    const { roleCode, partner } = auth;
    if (roleCode === 'admin' || roleCode === 'god') {
      dispatch({
        type: UPDATE_ADMIN_NAV,
      });
    }
    if (roleCode === 'god') {
      dispatch({
        type: UPDATE_GOD_NAV,
      });
    }
    if (roleCode === 'partner_admin' || roleCode === 'partner_finance') {
      dispatch({
        partnerId: partner,
        type: UPDATE_PARTNER_NAV,
      });
    }
    if (roleCode === 'partner_admin_audio_annotator' || roleCode === 'partner_audio_annotator') {
      dispatch({
        type: UPDATE_ANNOTATE_PARTNER_NAV,
      });
    }
    if (roleCode === 'partner_admin_audio_annotator') {
      dispatch({
        type: UPDATE_ADMIN_ANNOTATE_PARTNER_NAV,
      });
    }
  }
}
