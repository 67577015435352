export const RESET_DASHBOARD_DETAIL = 'RESET_DASHBOARD_DETAIL';
export const GET_DASHBOARD_DETAIL_SUCCESS = 'GET_DASHBOARD_DETAIL_SUCCESS';

export function resetPOIDetail() {
  return {
    type: 'RESET_DASHBOARD_DETAIL',
  };
}

export function getDashboardDetail() {
  return async(dispatch, getState) => {
    dispatch({
      type: 'GET_DASHBOARD_DETAIL_REQUEST'
    });
    return fetch(`/api/admin/v1/dashboard`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("accessToken"),
      },
    })
      .then(async(result) => {
        const jsonResult = await result.json();
        if (result.ok) {
          return dispatch({
            type: 'GET_DASHBOARD_DETAIL_SUCCESS',
            payload: jsonResult,
          });
        } else {
          throw Error(`[${result.status}] ${result.statusText}: ${jsonResult.message}`);
        }
      })
      .catch((error) => dispatch({
        type: 'GET_DASHBOARD_DETAIL_FAILURE',
        payload: error,
        error: true
      }));
  };
}