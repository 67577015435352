import _ from 'lodash';
import {
  GET_PARTNER_COUPONS_SUCCESS,
  GET_PARTNER_COUPON_DETAIL_SUCCESS,
  CREATE_PARTNER_COUPON_SUCCESS,
  RESET_PARTNER_COUPON_DETAIL
} from '../actions/partnerCoupon';

export const dataInitialState = {
  partnerCoupons: {
    data: [],
    meta: {},
  },
  partnerCouponDetail: {
  },
};

export const ACTION_HANDLERS = {
  [GET_PARTNER_COUPONS_SUCCESS]: (state = {}, action) => {
    const result = { ...state };
    const { data, meta } = action.payload;
    _.set(result, `partnerCoupons.data`, data);
    _.set(result, `partnerCoupons.meta`, meta);
    return result;
  },
  [GET_PARTNER_COUPON_DETAIL_SUCCESS]: (state = {}, action) => {
    return { ...state, partnerCouponDetail: action.payload.data };
  },
  [CREATE_PARTNER_COUPON_SUCCESS]: (state = {}, action) => {
    return {
      ...state,
      partnerCouponDetail: {
        ...state.partnerCouponDetail,
        ...action.payload.data,
      },
    };
  },
  [RESET_PARTNER_COUPON_DETAIL]: (state = {}, action) => {
    return { ...state, partnerCouponDetail: {} };
  },
};

export default function (state = dataInitialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
