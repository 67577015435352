import { combineReducers } from 'redux';
import user from './user';
import story from './story';
import auth from './auth';
import role from './role';
import report from './report';
import event from './event';
import feedback from './feedback';
import log from './log';
import poi from './poi';
import blacklist from './blacklist';
import partner from './partner';
import pageSetting from './pageSetting';
import eventSequence from './eventSequence';
import mood from './mood';
import storyHelp from './storyHelp';
import sharingBonus from './sharingBonus';
import versionConfig from './versionConfig';
import navigation from './navigation';
import fileUpload from './fileUpload';
import partnerCoupon from './partnerCoupon';
import partnerUser from './partnerUser';
import appUserCoupon from './appUserCoupon';
import annotateUser from './annotateUser';
import storyAnnotate from './storyAnnotate';
import annotateStatistics from './annotateStatistics';
import announcement from './announcement';
import dashboard from './dashboard';
  
import apiLoading from './apiLoading';
import apiError from './apiError';

export default combineReducers({
  user,
  story,
  auth,
  role,
  report,
  event,
  feedback,
  log,
  poi,
  blacklist,
  partner,
  pageSetting,
  eventSequence,
  mood,
  storyHelp,
  sharingBonus,
  versionConfig,
  navigation,
  fileUpload,
  partnerCoupon,
  partnerUser,
  appUserCoupon,
  annotateUser,
  storyAnnotate,
  annotateStatistics,
  announcement,
  dashboard,
  apiLoading,
  apiError,
});
